export default {
    xseed: 'XSeed快速开发平台',
    usernameLogin: '账户密码登录',
    mobileLogin: '手机短信登录',
    autoLogin: '自动登录',
    forgetPass: '忘记密码',
    otherLogin: '其他方式登录',
    regist: '注册账户',
    login: '登录',
    logining: '登录中...',
    home: '首页',
    switchLangTitle: '切换语言',
    international: '多语言切换',
    userCenter: '个人中心',
    changePass: '修改密码',
    changeEmail: '修改邮箱',
    logout: '退出登录',
    tagOption: '标签选项',
    closeAll: '关闭全部',
    closeOthers: '关闭其他',
    help: '帮助',
    privacy: '隐私',
    terms: '条款',
    rights: '版权所有'
};
