import axios from 'axios';
import { getDict } from '@/api/index';

let dictUtil = {

};

// 获取常用的数据字典保存至vuex
dictUtil.initDictData = function (vm) {
    // axios.get(getDictData + "sex").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setSex", res.result);
    //     }
    // });
    // axios.get(getDictData + "message_type").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setMessageType", res.result);
    //     }
    // });
    // axios.get(getDictData + "priority").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setPriority", res.result);
    //     }
    // });
    // axios.get(getDictData + "leave_type").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setLeaveType", res.result);
    //     }
    // });
    // axios.get(getDictData + "plat_type").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setPlatType", res.result);
    //     }
    // });    
    // axios.get(getDictData + "wake_type").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setWakeType", res.result);
    //     }
    // }); 
    // axios.get(getDictData + "ajlx").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setAjlx", res.result);
    //     }
    // }); 
    // axios.get(getDictData + "ajzt").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setAjzt", res.result);
    //     }
    // }); 
    // axios.get(getDictData + "blzt").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setBlzt", res.result);
    //     }
    // }); 

    // axios.get(getDictData + "scdj").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setScdj", res.result);
    //     }
    // }); 
    // axios.get(getDictData + "zyb").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setZyb", res.result);
    //     }
    // }); 
    // axios.get(getDictData + "xb").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setXb", res.result);
    //     }
    // }); 
    // axios.get(getDictData + "hldj").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setHldj", res.result);
    //     }
    // }); 
    // axios.get(getDictData + "hjlx").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setHjlx", res.result);
    //     }
    // }); 
    // axios.get(getDictData + "fyrs").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setFyrs", res.result);
    //     }
    // }); 
    // axios.get(getDictData + "tjsjnf").then(res => {
    //     if(res.success){
    //         vm.$store.commit("setTjsjnf", res.result);
    //     }
    // }); 
    axios.get(getDict).then(res => {
        if(res.success){listDictVo
            let listDictVo = res.result;
            for(var i=0;i<listDictVo.length;i++) {
                // alert(listDictVo[i].dictType);
                Vue.set(vm.$store.state.dict, listDictVo[i].dictType, listDictVo[i].listDictData);
            }
        }
    }); 
};

export default dictUtil;
