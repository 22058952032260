const dict = {
    state: {
        // 经常需要读取的数据字典
        // sex: [],
        // messageType: [],
        // priority: [],
        // leaveType: [],
        // platType: [],
        // wakeType: [],
        // ajlx: [],
        // ajzt:[],
        // blzt:[],
        // scdj:[],
        // zyb:[],
        // xb:[],
        // hldj:[],
        // // hjlx:[],
        // fyrs:[],
        // tjsjnf:[],
    },
    mutations: {
        // 设置值的改变方法
        // setSex(state, list) {
        //     state.sex = list;
        // },
        // setMessageType(state, list) {
        //     state.messageType = list;
        // },
        // setPriority(state, list) {
        //     state.priority = list;
        // },
        // setLeaveType(state, list) {
        //     state.leaveType = list;
        // },
        // setPlatType(state, list) {
        //     state.platType = list;
        // },
        // setWakeType(state, list) {
        //     state.wakeType = list;
        // },
        // setAjlx(state, list) {
        //     state.ajlx = list;
        // },
        // setAjzt(state, list) {
        //     state.ajzt = list;
        // },
        // setBlzt(state, list) {
        //     state.blzt = list;
        // },
        // setScdj(state, list) {
        //     state.scdj = list;
        // },
        // setZyb(state, list) {
        //     state.zyb = list;
        // },
        // setXb(state, list) {
        //     state.xb = list;
        // },
        // setHldj(state, list) {
        //     state.hldj = list;
        // },
        // setHjlx(state, list) {
        //     state.hjlx = list;
        // },
        // setFyrs(state, list) {
        //     state.fyrs = list;
        // },
        // setTjsjnf(state, list) {
        //     state.tjsjnf = list;
        // },
    }
};

export default dict;
