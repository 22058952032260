export default {
    xseed: 'XSeed Platform',
    usernameLogin: 'Username Sign in',
    mobileLogin: 'Phone',
    autoLogin: 'Auto Login',
    forgetPass: 'Forget Password',
    otherLogin: 'Sign in with',
    regist: 'Register',
    login: 'Sign in',
    logining: 'Signing in...',
    home: 'home',
    switchLangTitle: 'Switch Lang',
    international: 'Switch Lang',
    userCenter: 'Account Center',
    changePass: 'Modify Pass',
    changeEmail: 'Modify Email',
    logout: 'Sign out',
    tagOption: 'Labels',
    closeAll: 'Close All',
    closeOthers: 'Close Others',
    help: 'Help',
    privacy: 'Privacy',
    terms: 'Terms',
    rights: 'All Rights Reserved'
};
